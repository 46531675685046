import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactSelect from "../../UI/ReactSelect";
import ReactMultiSelect from "../../UI/ReactMultiSelect";
import RenderIf from "app/modules/UI/RenderIf";
import {
  useGetCategoriesQuery,
  useGetCategoriesItemQuery,
  useUpdateCategoriesMutation,
} from "redux/api/Categories/categoriesApi";
import { useGetKeywordsQuery } from "redux/api/Searchkeyword/searchKeywordApi";

export default function Create(props) {
  const { show, onHide, id } = props;
  const [sourceOption, setSourceOption] = React.useState(null);
  const [sourceOptions, setSourceOptions] = React.useState(null);
  const [searchKeywordsOption, setSearchKeywordsOption] = React.useState([]);
  const [selectedKeywordsOption, setSelectedKeywordsOption] = React.useState([]);
  const [searchKeywordsOptions, setSearchKeywordsOptions] = React.useState(
    null
  );

  const initialValues = {
    name: "",
    parentCategoryId: "",
    searchKeywords: "",
  };

  const { data: CategoriesData } = useGetCategoriesQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });
  const { data: SearchKeywordDat } = useGetKeywordsQuery({
    skip: "",
    take: "",
    field: "id",
    orderBy: false,
  });
  const { data } = useGetCategoriesItemQuery(id);

  useEffect(() => {
    if (data) {
      const { id, name } = data;
      setValues({ id, name });
    }
  }, [data]);

  useEffect(() => {
    if (SearchKeywordDat) {
      const newSelectData = SearchKeywordDat?.data.map((item) => ({
        value: item.id,
        label: item.expression,
      }));
      setSearchKeywordsOptions([...newSelectData]);
    }
  }, [SearchKeywordDat]);

  useEffect(() => {
    if (CategoriesData) {
      const newSelectData = CategoriesData?.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setSourceOptions([{ label: "Heçbiri", value: 0 }, ...newSelectData]);
    }
  }, [CategoriesData]);

  useEffect(() => {
    const sourceOptionValue = sourceOptions?.find(
      (option) => option.value == data?.parentCategoryId
    );
    setSourceOption(sourceOptionValue);
    setFieldValue("parentCategoryId", sourceOptionValue?.value);
  }, [data, sourceOptions]);

  const [keywordChosen, setKeywordChosen] = React.useState([]);

  useEffect(() => {
    if (data?.categorySearchKeywords && searchKeywordsOptions) {
      setSelectedKeywordsOption(data?.categorySearchKeywords)
      data?.categorySearchKeywords?.map((item, index) => {
        keywordChosen.push(
          searchKeywordsOptions?.find(
            (option) => option.value == item.searchKeywordId
          )
        );
        return index;
      });
      setFieldValue("searchKeywords", (prev) => keywordChosen);
      setSearchKeywordsOption((prev) => keywordChosen);
    }
  }, [data, searchKeywordsOptions]);

  function handleChangeInterest(c) {
    setSearchKeywordsOption(c);
  

    const mockArray = c.map((element) => element.value);
    setFieldValue("searchKeywords", mockArray);
  
  
    const deletedOptions = searchKeywordsOption.filter(
      (option) => !c.some((selected) => selected.value === option.value)
    );
  
    if (deletedOptions.length > 0) {
      setSelectedKeywordsOption((prev) =>
        prev.filter((item) =>
          !deletedOptions.some((deleted) => deleted.value === item.searchKeywordId)
        )
      );
    }
  }
  

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const [
    updateCategories,
    { isLoading, isSuccess },
  ] = useUpdateCategoriesMutation();

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      onHide();
    }
  }, [isLoading]);

  const CreateSchema = Yup.object().shape({
    name: Yup.string().required("Başlıq daxil etməyiniz tələb olunur."),
   
  });

  function handleChangeRole(value) {
    setSourceOption(value);
    setFieldValue("parentCategoryId", value?.value);
  }

  const getInputClasses = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return true;
    }
    if (formik.touched[fieldName] && !formik.errors[fieldName]) {
      return false;
    }
    return false;
  };

  const onPostHumanResource = (values) => {
    const searchKeywordsArray = [
      // selectedKeywordsOption içindeki tüm öğeleri ekle
      ...(Array.isArray(selectedKeywordsOption)
        ? selectedKeywordsOption.map((item) => ({
            id: item?.id,
            searchKeywordId: item.searchKeywordId,
          }))
        : []),
    
      // values.searchKeywords içindeki öğeleri ekle, ancak yalnızca selectedKeywordsOption içinde bulunmayanları ekle
      ...(Array.isArray(values?.searchKeywords)
        ? values.searchKeywords
            .filter(
              (item) =>
                !selectedKeywordsOption.some(
                  (selectedItem) => selectedItem.searchKeywordId === item
                )
            )
            .map((item) => ({
              id: 0,
              searchKeywordId: item,
            }))
        : []),
    ];
    
    
  
    const categorySearchKeywords =
      searchKeywordsArray.length > 0
        ? searchKeywordsArray
        : selectedKeywordsOption || [];
  
    const humanResource = {
      id: id,
      name: values?.name,
      parentCategoryId:
        values?.parentCategoryId == 0 ? null : values?.parentCategoryId,
      categorySearchKeywords,
    };
  
    updateCategories(humanResource);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: CreateSchema,
    onSubmit: (values, { resetForm, setSubmitting }) => {
      onPostHumanResource(values, resetForm, setSubmitting);
    },
  });

  const { resetForm,  setFieldValue, setValues } = formik;


  return (
    <Modal
      size="xl"
      aria-labelledby="create"
      centered
      className="modal-center pr-0"
      show={show}
      onHide={onHide}
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="create">Düzəliş et</Modal.Title>
        </Modal.Header>
        <Modal.Body id="userModal">
          <div className="col-12 pl-4 pr-4">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <TextField
                    id="name"
                    name="name"
                    label="Ad"
                    placeholder="Ad"
                    className="w-100"
                    error={getInputClasses("name")}
                    {...formik.getFieldProps("name")}
                    onKeyDown={handleEnter}
                  />
                </div>
                <RenderIf condition={formik.touched.name && formik.errors.name}>
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.name}</div>
                  </div>
                </RenderIf>
              </div>
              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactSelect
                    suggestions={sourceOptions}
                    handleChangeSingle={(value) => handleChangeRole(value)}
                    single={sourceOption}
                    label="Daxil olduğu kategoriya"
                    placeholder="Daxil olduğu kategoriya"
                    inputId="parentCategoryId"
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.parentCategoryId &&
                    formik.errors.parentCategoryId
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.parentCategoryId}
                    </div>
                  </div>
                </RenderIf>
              </div>

              <div className="col-12 col-md-12 col-lg-6">
                <div className="w-100 pt-2 pb-3 d-flex flex-wrap">
                  <ReactMultiSelect
                    isMulti={true}
                    suggestions={searchKeywordsOptions}
                    handleChangeSingle={handleChangeInterest}
                    single={searchKeywordsOption}
                    label="Açar Sözlər"
                    placeholder="Açar Sözlər"
                    inputId="id"
                  />
                </div>
                <RenderIf
                  condition={
                    formik.touched.searchKeywords &&
                    formik.errors.searchKeywords
                  }
                >
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.searchKeywords}
                    </div>
                  </div>
                </RenderIf>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              onHide(false);
              formik.resetForm();
          
            }}
          >
            Bağla
          </Button>
          <Button type="submit" className={`px-9`}>
            Düzəliş et
            {isLoading && <span className="ml-3 spinner spinner-white"></span>}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
