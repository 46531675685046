import { useMemo, useState, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useHistory } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EyeIcon from "@material-ui/icons/RemoveRedEye";
import PlusIcon from "@material-ui/icons/Add";
import { toast } from "react-toastify";
import { GrPowerReset } from "react-icons/gr";
import { format } from "date-fns";

const Example = ({
  data,

  setOpenView,
  setOpenEdit,
  setOpenRemove,
  setCategoryId,
  setOpenCreate,
  refetch,
}) => {
  const params = new URLSearchParams(window.location.search);
  const [pagination, setPagination] = useState({
    pageIndex: parseInt(params.get("page"), 10) || 0,
    pageSize: parseInt(params.get("rowsPerPage"), 10) || 10,
  });

  const history = useHistory();
  const nestedData = useMemo(() => {
    const categoriesMap = {};
    const rootCategories = [];

    data?.data?.forEach((category) => {
      categoriesMap[category.id] = { ...category, subRows: [] };
    });

    data?.data?.forEach((category) => {
      if (category.parentCategoryId) {
        categoriesMap[category.parentCategoryId]?.subRows.push(
          categoriesMap[category.id]
        );
      } else {
        rootCategories.push(categoriesMap[category.id]);
      }
    });

    return rootCategories;
  }, [data]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Kateqoriya adı",
      },
      {
        accessorKey: "created",
        header: "Yaradılma tarixi",
        Cell: ({ cell }) =>
          cell.getValue()
            ? format(new Date(cell.getValue()), "yyyy-MM-dd")
            : null,
      },
      {
        id: "operations",
        accessorKey: "id",
        header: "Əməliyyatlar",
        enableColumnFilter: false,
        Cell: (row) => (
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
          >
            <Tooltip title="Hamısını göstər" placement="top">
              <button
                aria-label="view"
                className="btn btn-sm btn-icon btn-bg-light btn-text-info btn-hover-info mr-2"
                onClick={(e) => {
                  setOpenView(e);
                  setCategoryId(row?.renderedCellValue);
                }}
              >
                <EyeIcon />
              </button>
            </Tooltip>

            <Tooltip title="Düzəliş et" placement="top">
              <button
                aria-label="edit"
                className="btn btn-sm btn-icon btn-bg-light btn-text-dark btn-hover-dark mr-2"
                onClick={() => {
                  setOpenEdit(true);
                  setCategoryId(row?.renderedCellValue);
                }}
              >
                <EditIcon />
              </button>
            </Tooltip>

            <Tooltip title="Sil" placement="top">
              <button
                aria-label="delete"
                className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
                onClick={() => {
                  setOpenRemove(true);
                  setCategoryId(row?.renderedCellValue);
                }}
              >
                <DeleteIcon />
              </button>
            </Tooltip>
          </div>
        ),
      },
    ],
    [setOpenView, setOpenEdit, setOpenRemove, setCategoryId]
  );

  useEffect(() => {
    const newUrl = `?page=${pagination.pageIndex}&rowsPerPage=${pagination.pageSize}`;
    history.replace(newUrl);
  }, [pagination.pageIndex, pagination.pageSize]);
  const [columnFilters, setColumnFilters] = useState([]);
  const table = useMaterialReactTable({
    columns,
    data: nestedData,
    enableExpandAll: true,
    enableExpanding: true,
    enableGlobalFilter: false,
    filterFromLeafRows: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnActions: false,
    getSubRows: (row) => row.subRows,
    initialState: { expanded: false },
    paginateExpandedRows: false,
    onPaginationChange: setPagination,
    muiTableContainerProps: { sx: { maxHeight: "350px" } },
    enableStickyHeader: true,
    enableStickyFooter: true,
    state: {
      columnFilters,
      pagination,
    },
    onColumnFiltersChange: (updater) => {
      const newFilters =
        typeof updater === "function" ? updater(columnFilters) : updater;
      if (newFilters.length === 0) {
        table.setExpanded(false);
      } else {
        table.setExpanded(true);
      }
      setColumnFilters(newFilters);
    },
  });

  const handleRefetch = async () => {
    try {
      await refetch();
      toast.success("Məlumatlar uğurla yeniləndi!");
    } catch (error) {
      toast.error("Yeniləmə zamanı səhv baş verdi!");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="d-flex justify-content-end w-100">
        <button className="reset_wrapper mr-2 mt-1" onClick={handleRefetch}>
          <GrPowerReset />
        </button>
        <Tooltip title="Əlavə et" placement="top">
          <button
            className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary mr-2 h5 rounded-circle flex-shrink-0"
            onClick={() => setOpenCreate(true)}
          >
            <PlusIcon />
          </button>
        </Tooltip>
      </div>
      <MaterialReactTable table={table} />
    </div>
  );
};

export default Example;
